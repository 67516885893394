import React, { Component, createRef } from "react";
import "../../index.scss";
import "../common/blank-nav.css";
import fb from "../../config/fb";
import Modal from 'react-bootstrap/Modal'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { Button } from 'reactstrap';

class TermsAndConditions extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
  }

  this.myref = createRef();
  }

  componentDidMount() {
    const analytics = fb.analytics();
    analytics.logEvent("terms_condition");

  //   this.myref.addEventListener("mouseleave", () => {
  //     this.openModal();
  //     console.log('Evento');
  // }, false);
  }

  // openModal = () => {
  //   const storageShowModal = sessionStorage.getItem('modalShow');
  //   console.log(storageShowModal);
  //   if (storageShowModal === null) {
  //       this.setState({ modalIsOpen: true });
  //       sessionStorage.setItem('modalShow', true);
  //   }
  // }

closeModal = () => {
    this.setState({ modalIsOpen: false });
}

goDonate = () => {
    window.open("/Donation");
}

  render() {
    return (
      <div ref={element => this.myref = element} className="header-blank">

        {/* <Modal show={this.state.modalIsOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>Help grow Ventazer</Modal.Title>
          </Modal.Header>
          <Modal.Body>In order to maintain Ventazer as a fee-free event platform, we invite you to support our cause with a donation of any size to help us with upkeep and early growth costs. For every $1 donated, we will donate 30% of the proceeds to COVID-19 vaccine development programs and to help those in the event industry that have lost their jobs as a direct result of the pandemic.</Modal.Body>
          <Modal.Footer>
            <Route basename="/">
              <Link to={'/Donation'} className="btn-goDonation">I want to help!</Link>
            </Route>
            <Button className="btn-close" onClick={this.closeModal}>
              Think what we can do together and donate later
                        </Button>
          </Modal.Footer>
        </Modal> */}

        <div class="section light-bg">
          <div class="container">
            <div class="row">
              <div class="col-md-12 d-flex align-items-center">
                <div>
                  <h2 class="text-header">Terms of Use - VENTAZER</h2>
                  <p class="mb-4 text-content">Last Updated: May 6, 2019</p>
                  <p class="mb-4 text-content">
                    Welcome to VENTAZER! The following are the terms of use
                    ("Terms") that govern your use of the Ventazer application
                    where this appears ("Application"). Our Privacy Policy,
                    Disclaimer, and any other policies, rules or guidelines that
                    may be applicable to particular offers or features on the
                    Application are also incorporated into these Terms. By
                    visiting or using the Application, you expressly agree to
                    these Terms, as updated from time to time.
                  </p>
                  <p class="mb-4 text-content">
                    We may make changes to these Terms at any time. Any changes
                    we make will be effective immediately when we post a revised
                    version of these Terms on the Application. The "Last
                    Updated" date above will tell you when these Terms were last
                    revised. By continuing to use this Application after that
                    date, you agree to the changes.{" "}
                  </p>
                  <p class="mb-4 text-content">
                    While some of the events listed on the Application may
                    appeal to children, the Application is not targeted at
                    children under the age of 18, and they are not permitted to
                    use the Application. We strongly encourage all parents and
                    guardians to monitor the Internet use by their children. If
                    you use the Application, you affirm you are at least 18
                    years old.{" "}
                  </p>
                  <ul class="list-unstyled ui-steps">
                    <li class="media">
                      <div class="media-body">
                        <h5>Account Registration</h5>
                        <p>
                          You will be required to create an account or sign up
                          to browse the Application. Your account username may
                          not include the name of another person with the intent
                          to impersonate that person, or be offensive, vulgar or
                          obscene. Your account username and password are
                          personal to you. You will be responsible for the
                          confidentiality and use of your username and password,
                          and for all activities (including purchases) that are
                          conducted through your account. You may not transfer
                          or sell access to your account. We will not be liable
                          for any harm related to disclosure of your username or
                          password or the use by anyone else of your username or
                          password. You may not use another user's account
                          without that user's permission. You will immediately
                          notify us in writing if you discover any unauthorized
                          use of your account or other account-related security
                          breach. We may require you to change your username
                          and/or password if we believe your account is no
                          longer secure or if we receive a complaint that your
                          username violates someone else's rights. You will have
                          no ownership in your account or your username. We may
                          refuse registration, cancel an account or deny access
                          to the Application for any reason.
                        </p>
                      </div>
                    </li>
                    <li class="media my-4">
                      <div class="media-body">
                        <h5>Code of Conduct</h5>
                        <p>
                          You agree that you will comply with all applicable
                          laws, rules and regulations, and that you will not:
                        </p>
                        <ul>
                          <li>
                            Restrict or inhibit any other person from using the
                            Application.{" "}
                          </li>
                          <li>Use the Application for any unlawful purpose.</li>
                          <li>
                            Express or imply that any statements you make are
                            endorsed by us, without our prior written consent.
                          </li>
                          <li>
                            Impersonate any person or entity, whether actual or
                            fictitious, including any employee or representative
                            of our company.
                          </li>
                          <li>
                            Submit (a) any content or information that is
                            unlawful, fraudulent, libelous, defamatory, or
                            otherwise objectionable, or infringes our or any
                            third party's intellectual property or other rights;
                            (b) any non-public information about companies
                            without authorization; or (c) any advertisements,
                            solicitations, chain letters, pyramid schemes,
                            surveys, contests, investment opportunities or other
                            unsolicited commercial communication.
                          </li>
                          <li>
                            Submit, or provide links to, any postings containing
                            material that could be considered harmful, obscene,
                            pornographic, sexually explicit, indecent, lewd,
                            violent, abusive, profane, insulting, threatening,
                            harassing, hateful or otherwise objectionable,
                            includes the image or likeness of individuals under
                            18 years of age, encourages or otherwise depicts or
                            glamorizes drug use (including alcohol and
                            cigarettes), characterizes violence as acceptable,
                            glamorous or desirable, or contains any personal
                            contact information or other personal information
                            identifying any third party.
                          </li>
                          <li>
                            Submit, or provide links to, any postings containing
                            material that harasses, victimizes, degrades, or
                            intimidates an individual or group of individuals on
                            the basis of religion, race, ethnicity, sexual
                            orientation, gender, age, or disability.
                          </li>
                          <li>Engage in spamming or flooding.</li>
                          <li>
                            Harvest or collect information about Application
                            users.
                          </li>
                          <li>
                            Order a number of tickets for an event that exceeds
                            the stated limit for that event.
                          </li>
                          <li>
                            Use any password or code to participate in a presale
                            or other offer on the Application if you did not
                            receive the password or code from us or if you
                            violate the terms of the presale or offer; or
                          </li>
                          <li>
                            {" "}
                            Use any area of the Application for commercial
                            purposes, such as to conduct sales of tickets,
                            products or services.{" "}
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>
                          Ownership of Content and Grant of Conditional License
                        </h5>
                        <p>
                          The Application and all data, text, designs, pages,
                          print screens, images, artwork, photographs, audio and
                          video clips, and HTML code, source code, or software
                          that reside or are viewable or otherwise discoverable
                          on the Application, and all tickets obtained from the
                          Application, (collectively, the "Content") are owned
                          by us or our licensors. We may own a copyright and, in
                          many instances, patents and other intellectual
                          property in the Application and Content. We may change
                          the Content and features of the Application at any
                          time.{" "}
                        </p>
                        <p>
                          We grant you a limited, conditional, no-cost,
                          non-exclusive, non-transferable, non-sub-licensable
                          license to view this Application and its Content as
                          permitted by these Terms for non-commercial purposes
                          only if, as a condition precedent, you agree that you
                          will not:
                        </p>
                        <ul>
                          <li>
                            Submit any software or other materials that contain
                            any viruses, worms, Trojan horses, defects, date
                            bombs, time bombs or other items of a destructive
                            nature.
                          </li>
                          <li>
                            Manipulate identifiers, including by forging
                            headers, in order to disguise the origin of any
                            posting that you submit.
                          </li>
                          <li>
                            Link to any portion of the Application other than
                            the URL assigned to the homepage of the Application.
                          </li>
                          <li>
                            "Frame" or "mirror" any part of the Application.{" "}
                          </li>
                          <li>
                            Modify, adapt, sub-license, translate, sell, reverse
                            engineer, decompile or disassemble any portion of
                            the Application or otherwise attempt to derive any
                            source code or underlying ideas or algorithms of any
                            part of the Content.{" "}
                          </li>
                          <li>
                            Remove any copyright, trademark or other proprietary
                            rights notices contained on the Application.{" "}
                          </li>
                          <li>
                            Use any robot, spider, offline reader, Application
                            search/retrieval application or other manual or
                            automatic device, tool, or process to retrieve,
                            index, data mine or in any way reproduce or
                            circumvent the navigational structure or
                            presentation of the Content or the Application,
                            including with respect to any CAPTCHA displayed on
                            the Application. Operators of public search engines
                            may use spiders to copy materials from the
                            Application for the sole purpose of and solely to
                            the extent necessary for creating publicly available
                            searchable indices of the materials, but not caches
                            or archives of such materials. We may revoke this
                            exception at any time and require removal of
                            archived materials gathered in the past.{" "}
                          </li>
                          <li>
                            Use any automated software or computer system to
                            search for, reserve, buy or otherwise obtain
                            tickets, discount codes (including Concert Cash®),
                            promotional codes, vouchers, gift cards or any other
                            items available on the Application, including
                            sending information from your computer to another
                            computer where such software or system is active.{" "}
                          </li>
                          <li>
                            Take any action that imposes or may impose (in our
                            sole discretion) an unreasonable or
                            disproportionately large load on our infrastructure.{" "}
                          </li>
                          <li>
                            Access, reload or refresh transactional event or
                            ticketing pages, or make any other request to
                            transactional servers, more than once during any
                            three-second interval.{" "}
                          </li>
                          <li>
                            Request more than 1,000 pages of the Application in
                            any 24-hour period, whether alone or with a group of
                            individuals.{" "}
                          </li>
                          <li>
                            Make more than 800 reserve requests on the
                            Application in any 24-hour period, whether alone or
                            with a group of individuals.{" "}
                          </li>
                          <li>
                            Reproduce, modify, display, publicly perform,
                            distribute or create derivative works of the
                            Application or the Content.{" "}
                          </li>
                          <li>
                            Reproduce or scan tickets in a format or medium
                            different from that provided by the Application.{" "}
                          </li>
                          <li>
                            Decode, decrypt, modify, or reverse engineer any
                            tickets or underlying algorithms or barcodes used on
                            or in production of tickets or the Application.{" "}
                          </li>
                          <li>
                            Use the Application or the Content in an attempt to,
                            or in conjunction with, any device, program or
                            service designed to circumvent any technological
                            measure that effectively controls access to, or the
                            rights in, the Application and/or Content in any way
                            including, without limitation, by manual or
                            automatic device or process, for any purpose.{" "}
                          </li>
                          <p>
                            This license is expressly conditioned on your
                            preexisting agreement to comply with, and your
                            actual compliance with, each of the provisions
                            described in this Ownership of Content and Grant of
                            Conditional License section. This license exists
                            only so long as you strictly comply with each of the
                            provisions described in this section. Any use of the
                            Application or Content by you or anyone acting on
                            your behalf that does not strictly comply with each
                            and every provision in this section exceeds the
                            scope of the license granted to you herein,
                            constitutes unauthorized reproduction, display, or
                            creation of unauthorized derivative versions of the
                            Application and Content, and infringes our
                            copyrights, trademarks, patents and other rights in
                            the{" "}
                            <p>
                              Application and Content. You will not acquire any
                              ownership rights by using the Application or the
                              Content.{" "}
                            </p>
                            The registered and unregistered trademarks, logos
                            and service marks displayed on the Application are
                            either owned by us or our licensors. You may not use
                            our trademarks, logos and service marks in any way
                            without prior written permission. You may inquire
                            about obtaining permission by contacting us at{" "}
                            <a href="mailto:developer@ventazer.com">
                              developer@ventazer.com
                            </a>
                            .
                          </p>
                        </ul>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Making Purchases</h5>
                        <p>
                          Please review our <b>Purchase Policy</b>, which will
                          govern your purchase of any tickets or other products
                          through the Application, including any refunds or
                          exchanges. We may impose conditions on your use of any
                          coupon, promotional code or gift card. You will pay
                          all charges incurred by you or any users of your
                          account and credit card (or other applicable payment
                          mechanism) at the price(s) in effect when such charges
                          are incurred, including any applicable taxes. You may
                          only use credit or debit cards, gift cards or vouchers
                          that belong to you or to people who expressly
                          authorize you to use such payment methods. You may not
                          attempt to conceal your identity by using multiple
                          Internet Protocol addresses or email addresses to
                          conduct transactions on the Application. You will not
                          hold us liable if you do not comply with laws related
                          to your transactions. We may provide law enforcement
                          with information you provide to us related to your
                          transactions to assist in any investigation or
                          prosecution of you. If we are unable to verify or
                          authenticate any information or tickets you provide
                          during any registration, ordering, purchase, ticket
                          posting, sale, authentication, delivery, payment or
                          remittance process, or any other process, or if we are
                          no longer able to verify or authorize your credit card
                          or bank account information, your tickets may be
                          cancelled, we may refuse to honor all pending and
                          future ticket purchases made with those credit card or
                          bank accounts and/or via any online accounts
                          associated with those credit card or bank accounts. We
                          may also prohibit you from using the Application.
                        </p>
                      </div>
                    </li>
                    <li class="media my-4">
                      <div class="media-body">
                        <h5>Forums and User Content</h5>
                        <p>
                          We may host fan reviews, message boards, blog feeds,
                          social media feeds and other forums found on the
                          Application (collectively, "Forums"), and you may be
                          able to submit suggestions, reviews, concepts, audio
                          and video recordings, photographs, artwork or other
                          materials to the forums or other areas of the
                          Application ("User Content").{" "}
                        </p>
                        <p>
                          By submitting User Content, you certify that you are
                          at least 18 years old, or you are at least 13 years
                          old and have obtained your parent's or legal
                          guardian's express consent to submit User Content. You
                          own all rights to your User Content. If you submit
                          User Content to the Application, you grant us a
                          worldwide, non-exclusive, transferable,
                          sublicenseable, royalty-free right and license to use,
                          reproduce, modify, create derivative works of,
                          distribute, publicly perform, display, archive and
                          commercialize your User Content, in our sole
                          discretion, in all formats and in all media channels
                          now known or hereinafter discovered, without any
                          compensation or acknowledgment to you or anyone else.
                          This license will not affect your ownership in your
                          User Content, including the right to grant additional
                          licenses to your User Content, except if it conflicts
                          with these Terms. We are not obligated to post,
                          display or otherwise use any User Content, or to
                          attribute your User Content to you. You will not make
                          or authorize any claim against us that our use of your
                          User Content infringes any of your rights.{" "}
                        </p>
                        <p>
                          Statements, opinions and reviews posted by
                          participants in a forum may be inaccurate, offensive,
                          obscene, threatening or harassing. We do not endorse
                          and are not responsible for these postings. We will
                          not be liable for any loss or harm caused by the
                          posting or your reliance on information obtained
                          through the postings.{" "}
                        </p>
                        <p>
                          You will be responsible for your User Content and the
                          consequences of posting it. By submitting User
                          Content, you represent to us that (i) you own, or have
                          the necessary permission to submit the User Content
                          and to grant the licenses to us under this section,
                          and (ii) you have the written permission of every
                          identifiable person in the User Content to use that
                          person's name and likeness in the manner contemplated
                          by the Application and these Terms or, if the person
                          is a minor, the written permission of the minor's
                          parent or legal guardian.{" "}
                        </p>
                        <p>
                          We will have the right (but not the obligation) to
                          monitor the Application, the forums and the User
                          Content, and to disclose any User Content and the
                          circumstances surrounding its submission in order to
                          operate the Application properly, or to protect
                          ourselves, our sponsors and our users, or to comply
                          with legal obligations or governmental requests.{" "}
                        </p>
                        <p>
                          If we are notified that your User Content does not
                          comply with these Terms, we may investigate the
                          allegation and may decide to remove your User Content
                          and cancel your account. We may also hold you liable
                          for any User Content that infringes the rights of a
                          third party and require you to pay or reimburse us for
                          any amounts we believe are necessary to resolve any
                          complaint.{" "}
                        </p>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>
                          Claims of Copyright Infringement on the Application
                        </h5>
                        <p>
                          Under the Digital Millennium Copyright Act of 1998
                          (the "DMCA") if you believe in good faith that any
                          content on the Application infringes your copyright,
                          you may send us a notice requesting that the content
                          be removed. The notice must include: (a) your (or your
                          agent's) physical or electronic signature. (b)
                          identification of the copyrighted work on our
                          Application that is claimed to have been infringed (or
                          a representative list if multiple copyrighted works
                          are included in one notification). (c) identification
                          of the content that is claimed to be infringing or the
                          subject of infringing activity, including information
                          reasonably sufficient to allow us to locate the
                          content on the Application. (d) your name, address,
                          telephone number and email address (if available). (e)
                          a statement that you have a good faith belief that use
                          of the content in the manner complained of is not
                          authorized by you or your agent or the law. and (f) a
                          statement that the information in the notification is
                          accurate and, under penalty of perjury, that you or
                          your agent is authorized to act on behalf of the
                          copyright owner. If you believe in good faith that a
                          notice of copyright infringement has been wrongly
                          filed against you, you may send us a counter-notice.
                          You may read more information about the DMCA at
                          http://www.loc.gov/copyright.{" "}
                        </p>
                        <p>
                          Notices and counter-notices should be sent to
                          Ventazer, Inc., via email at:{" "}
                          <a href="mailto:developer@ventzaer.com">
                            developer@ventzaer.com
                          </a>{" "}
                          There can be penalties for false claims under the
                          DMCA. We suggest that you consult your legal advisor
                          before filing a notice or counter-notice. It is our
                          policy to terminate, in appropriate circumstances, the
                          access rights to the Application of repeat infringers.{" "}
                        </p>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Links</h5>
                        <p>
                          The Application contains links to other web
                          Applications that may not be owned or operated by us.
                          The fact that we may link to those web Applications
                          does not indicate any approval or endorsement of those
                          web Applications. We have no control over those web
                          Applications. We are not responsible for the content
                          of those web Applications, or the privacy practices of
                          those web Applications. We strongly encourage you to
                          become familiar with the terms of use and practices of
                          any linked web Application. Your use of other web
                          Applications is at your own risk and is subject to the
                          terms of those web Applications. It is up to you to
                          take precautions to ensure that whatever links you
                          select or software you download (whether from the
                          Application or other Applications) is free of viruses,
                          worms, Trojan horses, defects, date bombs, time bombs
                          and other items of a destructive nature.
                        </p>
                      </div>
                    </li>
                    <li class="media my-4">
                      <div class="media-body">
                        <h5>Parental Controls</h5>
                        <p>
                          We cannot prohibit minors from visiting our
                          Application and must rely on parents and guardians to
                          decide what materials are appropriate for children to
                          view and purchase. There are parental control
                          protections (such as computer hardware, software or
                          filtering services) available that may assist you in
                          limiting access to material that is harmful to minors.
                          You can find information about parental controls at{" "}
                          <a href="http://onguardonline.gov">
                            http://onguardonline.gov
                          </a>
                          . We do not endorse the products or services listed at
                          this web Application.
                        </p>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Access from Outside the United States</h5>
                        <p>
                          The Application is directed to people residing in the
                          United States. We do not represent that Content
                          available on or through the Application is appropriate
                          or available in other locations. We may limit the
                          availability of the Application or any service or
                          product described on the Application to any person or
                          geographic area at any time. If you choose to access
                          the Application from outside the United States, you do
                          so at your own risk.
                        </p>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Rules for Sweepstakes, Contests and Games</h5>
                        <p>
                          In addition to these Terms, sweepstakes, contests,
                          games or other promotions (collectively, "Promotions")
                          made available through the Application may have
                          specific rules that are different from these Terms. By
                          participating in a Promotion, you will become subject
                          to those rules. We urge you to review the rules before
                          you participate in a Promotion. Promotion rules will
                          control over any conflict with these Terms.
                        </p>
                      </div>
                    </li>
                    <li class="media my-4">
                      <div class="media-body">
                        <h5>Mobile Messaging</h5>
                        <p>
                          <p>
                            We offer browsing and mobile messaging services
                            which may include alerts, Promotions and offers for
                            products. You may choose to receive mobile alerts by
                            signing up or participating in a Promotion. If you
                            do, you authorize us to use automated technology to
                            send messages to the mobile phone number you supply
                            when you sign up. Your consent to receive mobile
                            communications is never required in order to
                            purchase something from us.{" "}
                          </p>
                          <p>
                            Message and data rates may apply, according to your
                            rate plan provided by your wireless carrier. We will
                            not be responsible for any text messaging or other
                            wireless charges incurred by you or by a person who
                            has access to your wireless device or telephone
                            number. You may not receive our alerts if your
                            carrier does not permit text alerts. Your carrier
                            may not allow you to use pre-paid phones or calling
                            plans to receive alerts. We may send you a bounce
                            back message for every message you send to us.
                            Service may not be compatible with all wireless
                            carriers or devices.{" "}
                          </p>
                          <p>
                            You may opt out of any alerts by replying to an
                            alert with the text message "STOP" or by sending the
                            text message "STOP" to the short-code provided. If
                            you opt out by sending us a text message, we will
                            send you a text to confirm your request. If you do
                            not want to receive a confirmation text message, you
                            may opt out by sending an email to{" "}
                            <a href="mailto:developer@ventazer.com">
                              {" "}
                              developer@ventazer.com
                            </a>{" "}
                            with your request and mobile device number. It may
                            take us up to 10 days to remove your mobile device
                            number from our database. For additional help, text
                            "HELP" to the short-code provided, or email{" "}
                            <a href="mailto:developer@ventazer.com">
                              {" "}
                              developer@ventazer.com
                            </a>
                            We are not responsible for the accuracy of any
                            information displayed in our mobile messaging, for
                            any mis-delivery or untimely delivery of any mobile
                            messaging, or your deletion or failure to store any
                            mobile messaging from us.{" "}
                          </p>
                        </p>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Mobile Device Application</h5>
                        <p>
                          If you install or use our mobile application, software
                          and services, including any accompanying documentation
                          (collectively, "App"), we grant you a limited right to
                          install and use the App on a single authorized device
                          located in the United States and its territories or in
                          another country where we may offer the App. You may
                          use the App for your personal, non-commercial and
                          entertainment purposes only. We do not grant you any
                          rights to any related documentation, support,
                          upgrades, maintenance or other enhancements to the
                          App. We will not provide you with any device, internet
                          access or wireless connection to use the App. We are
                          not responsible for any interaction between you and
                          another App user, or information you transmit through
                          the App (including your location).
                        </p>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Violation of these Terms</h5>
                        <p>
                          We may investigate any violation of these Terms,
                          including unauthorized use of the Application. We may
                          provide law enforcement with information you provide
                          to us related to your transactions to assist in any
                          investigation or prosecution of you. We may take legal
                          action that we feel is appropriate. You agree that
                          monetary damages may not provide us a sufficient
                          remedy and that we may pursue injunctive or other
                          relief for your violation of these Terms. If we
                          determine that you have violated these Terms or the
                          law, or for any other reason or for no reason, we may
                          cancel your account, delete all your User Content and
                          prevent you from accessing the Application at any time
                          without notice to you. If that happens, you may no
                          longer use the Application or any Content. You will
                          still be bound by your obligations under these Terms.
                          You agree that we will not be liable to you or any
                          third-party for termination of your access to the
                          Application or to your account or any related
                          information, and we will not be required to make the
                          Application or your account or any related information
                          available to you. We may also cancel any ticket or
                          merchandise order, and tickets or merchandise acquired
                          through your order. We may refuse to honor pending and
                          future purchases made from all accounts we believe may
                          be associated with you, or cancel a ticket or ticket
                          order associated with any person we believe to be
                          acting with you, or cancel your ticket postings, or
                          exercise any other remedy available to us.{" "}
                        </p>
                        <p>
                          You agree that your abusive use of the Application may
                          cause damage and harm to us, including impaired
                          goodwill, lost sales and increased expenses. You also
                          agree that monetary damages for your abusive use of
                          the Application are difficult to determine and that if
                          you, or others acting with you directly or indirectly,
                          request more than 1,000 pages of the Application or
                          make more than 800 reserve requests on the Application
                          in any 24-hour period, you, and those acting with you,
                          will be jointly and severally liable for liquidated
                          damages in the amount of twenty-five cents (<p style={{ fontFamily: 'Roboto', display: "inline-block" }}>$0.25</p>) for
                          each page request or reserve request made during that
                          24-hour period which exceeds those limits.
                        </p>
                      </div>
                    </li>
                    <li class="media my-4">
                      <div class="media-body">
                        <h5>Disclaimer of Warranties</h5>
                        <p>
                          WE PROVIDE THE APPLICATION AND THE CONTENT TO YOU "AS
                          IS" AND "AS AVAILABLE". WE TRY TO KEEP THE APPLICATION
                          UP, BUG-FREE AND SAFE, BUT YOU USE IT AT YOUR OWN
                          RISK. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, AND TO
                          THE EXTENT THAT APPLICABLE LAW PERMITS THE DISCLAIMER
                          OF EXPRESS OR IMPLIED WARRANTIES, WE DISCLAIM ALL
                          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY
                          OF TITLE, NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY,
                          FITNESS FOR A PARTICULAR PURPOSE, OR WARRANTIES THAT
                          MAY ARISE FROM COURSE OF DEALING OR COURSE OF
                          PERFORMANCE OR USAGE OF TRADE. WE DO NOT GUARANTEE
                          THAT THE APPLICATION WILL ALWAYS BE SAFE, SECURE OR
                          ERROR-FREE OR THAT THE APPLICATION WILL ALWAYS
                          FUNCTION WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS.
                          WE ARE NOT RESPONSIBLE FOR THE ACTIONS OR INFORMATION
                          OF THIRD PARTIES, AND YOU RELEASE US FROM ANY CLAIMS
                          AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN
                          ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY
                          SUCH THIRD PARTIES. IF YOU ARE A CALIFORNIA RESIDENT,
                          YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: A
                          GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
                          CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR
                          HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH
                          IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED
                          HIS SETTLEMENT WITH THE DEBTOR.
                        </p>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Limitation of Liability</h5>
                        <p>
                          IN NO EVENT WILL WE OR OUR EVENT PROVIDERS, SUPPLIERS,
                          ADVERTISERS AND SPONSORS, BE RESPONSIBLE OR LIABLE TO
                          YOU OR ANYONE ELSE FOR, AND YOU HEREBY KNOWINGLY AND
                          EXPRESSLY WAIVE ALL RIGHTS TO SEEK, DIRECT, INDIRECT,
                          INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY
                          TYPE OTHER THAN OUT OF POCKET EXPENSES, AND ANY RIGHTS
                          TO HAVE DAMAGES MULTIPLIED OR OTHERWISE INCREASED,
                          ARISING OUT OF OR IN CONNECTION WITH THE APPLICATION,
                          THE CONTENT, OR ANY PRODUCT OR SERVICE PURCHASED
                          THROUGH THE APPLICATION, EVEN IF WE HAVE BEEN ADVISED
                          OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF
                          WHETHER THE CLAIM IS BASED UPON ANY CONTRACT, TORT, OR
                          OTHER LEGAL OR EQUITABLE THEORY. WITHOUT LIMITING THE
                          FOREGOING, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT WE
                          WILL HAVE NO LIABILITY OR RESPONSIBILITY WHATSOEVER
                          FOR (a) ANY FAILURE OF ANOTHER USER OF THE APPLICATION
                          TO CONFORM TO THE CODES OF CONDUCT, (b) PERSONAL
                          INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
                          WHETHER ARISING IN CONTRACT OR IN TORT, RESULTING FROM
                          YOUR ACCESS TO AND USE OF OUR APPLICATION, (c) ANY
                          UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
                          AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
                          FINANCIAL INFORMATION STORED THEREIN, (d) ANY BUGS,
                          VIRUSES, WORMS, TROJAN HORSES, DEFECTS, DATE BOMBS,
                          TIME BOMBS OR OTHER ITEMS OF A DESTRUCTIVE NATURE
                          WHICH MAY BE TRANSMITTED TO OR THROUGH OUR
                          APPLICATION, (e) ANY ERRORS, MISTAKES, INACCURACIES OR
                          OMISSIONS IN ANY CONTENT, OR (f) ANY LOST, STOLEN OR
                          DAMAGED TICKETS, OR THE FAILURE OF A VENUE TO HONOR A
                          TICKET. YOUR SOLE AND EXCLUSIVE REMEDY FOR
                          DISSATISFACTION WITH THE APPLICATION IS TO STOP USING
                          THE APPLICATION.{" "}
                        </p>
                        <p>
                          THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY
                          LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE. THE
                          ALLOCATION OF RISK BETWEEN US IS AN ESSENTIAL ELEMENT
                          OF THE BASIS OF THE BARGAIN BETWEEN US. OUR AGGREGATE
                          LIABILITY ARISING OUT OF THESE TERMS OR THE USE OF THE
                          APPLICATION WILL NOT EXCEED THE GREATER OF ONE HUNDRED
                          DOLLARS (<p style={{ fontFamily: 'Roboto', display: "inline-block" }}>$100</p>) OR THE AMOUNT YOU HAVE PAID US IN THE
                          PAST TWELVE MONTHS. IN NO EVENT WILL ATTORNEYS' FEES
                          BE AWARDED OR RECOVERABLE. OUR LIABILITY WILL BE
                          LIMITED UNDER THIS PARAGRAPH TO THE FULLEST EXTENT
                          PERMITTED BY APPLICABLE LAW, AND THE PROVISIONS OF
                          THIS PARAGRAPH WILL NOT APPLY TO THE EXTENT APPLICABLE
                          LAW PERMITS THE RECOVERY OF DAMAGES, ATTORNEYS’ FEES
                          OR COSTS OTHERWISE PROHIBITED UNDER THIS PARAGRAPH.
                          THE PROVISIONS OF THIS PARAGRAPH THAT (A) PROHIBIT
                          DAMAGES TO BE MULTIPLIED OR OTHERWISE INCREASED, (B)
                          IMPOSE A DAMAGES LIMITATION OF THE GREATER OF ONE
                          HUNDRED DOLLARS (<p style={{ fontFamily: 'Roboto', display: "inline-block" }}>$100</p>) OR THE AMOUNT YOU HAVE PAID US
                          IN THE PAST TWELVE MONTHS, AND (C) PROHIBIT THE
                          RECOVERY OF ATTORNEYS’ FEES AND COSTS, DO NOT APPLY IN
                          CERTAIN STATES, INCLUDING WITHOUT LIMITATION NEW
                          JERSEY, TO CLAIMS BROUGHT UNDER STATUTES PERMITTING
                          SUCH RECOVERY.
                        </p>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Indemnification</h5>
                        <p>
                          If anyone brings a claim against us related to your
                          use of the Application, the Content, your User Content
                          or your violation of these Terms, you agree to
                          indemnify, defend and hold us and our affiliated
                          companies, event providers, suppliers, advertisers and
                          sponsors, and each of our officers, directors,
                          employees, and agents, harmless from and against any
                          and all claims, damages, losses and expenses of any
                          kind (including reasonable legal fees and costs). We
                          reserve the right to take exclusive control and
                          defense of any claim, and you will cooperate fully
                          with us in asserting any available defenses.
                        </p>
                      </div>
                    </li>
                    <li class="media my-4">
                      <div class="media-body">
                        <h5>
                          Disputes, Including Mandatory Arbitration and Class
                          Action Waiver
                        </h5>
                        <p>
                          Any dispute or claim relating in any way to your use
                          of the Application, or to products or services sold or
                          distributed by us or through us, will be resolved by
                          binding arbitration rather than in court, with the
                          following exceptions:
                        </p>
                        <ul>
                          <li>
                            You may assert claims in small claims court if your
                            claims apply.{" "}
                          </li>
                          <li>
                            If a claim involves the conditional license granted
                            to you as described in the Ownership of Content and
                            Grant of Conditional License section above, either
                            of us may file a lawsuit in a federal or state court
                            located within Cook County, Illinois, and we both
                            consent to the jurisdiction of those courts for such
                            purposes. and{" "}
                          </li>
                          <li>
                            In the event that the arbitration agreement in these
                            Terms is for any reason held to be unenforceable,
                            any litigation against us (except for small-claims
                            court actions) may be commenced only in a federal or
                            state court located within Cook County, Illinois,
                            and we both consent to the jurisdiction of those
                            courts for such purposes.{" "}
                          </li>
                          <p>
                            The arbitration agreement in these Terms is governed
                            by the Federal Arbitration Act (FAA), including its
                            procedural provisions, in all respects. This means
                            that the FAA governs, among other things, the
                            interpretation and enforcement of this arbitration
                            agreement and all of its provisions, including,
                            without limitation, the class action waiver
                            discussed below. State arbitration laws do not
                            govern in any respect.{" "}
                          </p>
                          <p>
                            This arbitration agreement is intended to be broadly
                            interpreted and will survive termination of these
                            Terms. The arbitrator, and not any federal, state or
                            local court or agency, shall have exclusive
                            authority to the extent permitted by law to resolve
                            all disputes arising out of or relating to the
                            interpretation, applicability, enforceability or
                            formation of this Agreement, including, but not
                            limited to any claim that all or any part of this
                            Agreement is void or voidable. There is no judge or
                            jury in arbitration, and court review of an
                            arbitration award is limited. However, an arbitrator
                            can award on an individual basis the same damages
                            and relief as a court (including injunctive and
                            declaratory relief or statutory damages) and must
                            follow these Terms as a court would.{" "}
                          </p>
                          <p>
                            To begin an arbitration proceeding, you must send an
                            email requesting arbitration and describing your
                            claim to: Ventazer, Inc., at:{" "}
                            <a href="mailto:developer@ventazer.com">
                              developer@ventazer.com
                            </a>
                            . You may download the forms located at
                            http://www.jamsadr.com. The arbitration will be
                            conducted by JAMS under its Streamlined Arbitration
                            Rules and Procedures or, if applicable, its
                            Comprehensive Arbitration Rules and Procedures, and
                            any applicable supplemental rules including its
                            Consumer Arbitration Standards of Minimum Fairness.
                            The JAMS Rules are available online at
                            http://www.jamsadr.com or by calling{" "}
                            <a href="telto:(800)-352-5267">(800)-352-5267</a>.
                            Payment of all filing, administration and arbitrator
                            fees will be governed by JAMS's rules. We will
                            reimburse those fees for claims totaling less than
                            <p style={{ fontFamily: 'Roboto', display: "inline-block" }}>$10,000</p> unless the arbitrator determines the claims
                            are frivolous, but in no event will we pay for
                            attorneys' fees. You may choose to have the
                            arbitration conducted by telephone, based on written
                            submissions, or in person in the county where you
                            live or at another mutually agreed location.{" "}
                          </p>
                          <p>
                            We each agree that the arbitrator may not
                            consolidate more than one person's claims and may
                            not otherwise preside over any form of a
                            representative or class proceeding, and that any
                            dispute resolution proceedings will be conducted
                            only on an individual basis and not in a class,
                            consolidated or representative action. You agree to
                            waive any right to a jury trial or to participate in
                            a class action. If this specific provision is found
                            to be unenforceable, then the entirety of this
                            arbitration section will be null and void and
                            neither of us will be entitled to arbitrate our
                            dispute.{" "}
                          </p>
                          <p>
                            You agree that these Terms evidence a transaction
                            involving interstate commerce and will be governed
                            by and construed in accordance with federal law to
                            the fullest extent possible.{" "}
                          </p>
                        </ul>
                      </div>
                    </li>
                    <li class="media">
                      <div class="media-body">
                        <h5>Questions</h5>
                        <p>
                          If you have any questions, comments or complaints
                          regarding these Terms or the Application, please
                          contact us at:
                        </p>
                        <p>Ventazer, Inc., at: </p>

                        <p>
                          <a href="mailto:developer@ventazer.com">
                            developer@ventazer.com.
                          </a>{" "}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TermsAndConditions;
