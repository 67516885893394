import React, { Component, createRef } from 'react';
import '../../index.scss';
import '../common/blank-nav.css';
import fb from '../../config/fb'
import Modal from 'react-bootstrap/Modal'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { Button } from 'reactstrap';


class PrivacyPolicy extends Component {

    constructor() {
        super();
        this.state = {
            modalIsOpen: false
        }
        this.myref = createRef();
    }

    componentDidMount() {
        const analytics = fb.analytics();
        analytics.logEvent('privacy_policy');
        
        // this.myref.addEventListener("mouseleave", () => {
        //     this.openModal();
        //     console.log('Evento');
        // }, false);
    }

    // openModal = () => {
    //     const storageShowModal = sessionStorage.getItem('modalShow');
    //     console.log(storageShowModal);
    //     if (storageShowModal === null) {
    //         this.setState({ modalIsOpen: true });
    //         sessionStorage.setItem('modalShow', true);
    //     }
    // }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    goDonate = () => {
        window.open("/Donation");
    }

    render() {
        return (
            <>
                <div ref={element => this.myref = element} class="section light-bg">

                    {/* <Modal show={this.state.modalIsOpen} onHide={this.closeModal}>
                        <Modal.Header>
                            <Modal.Title>Help grow Ventazer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>In order to maintain Ventazer as a fee-free event platform, we invite you to support our cause with a donation of any size to help us with upkeep and early growth costs. For every $1 donated, we will donate 30% of the proceeds to COVID-19 vaccine development programs and to help those in the event industry that have lost their jobs as a direct result of the pandemic.</Modal.Body>
                        <Modal.Footer>
                            <Route basename="/">
                                <Link to={'/Donation'} className="btn-goDonation">I want to help!</Link>
                            </Route>
                            <Button className="btn-close" onClick={this.closeModal}>
                                Think what we can do together and donate later
                        </Button>
                        </Modal.Footer>
                    </Modal> */}

                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 d-flex align-items-center"><div>
                                <h2 class="text-header">PRIVACY POLICY</h2>
                                <p class="mb-0 text-content">Welcome to Ventazer. We understand that privacy online is important to users of our
                                application, especially when conducting business. This statement governs our privacy policies
                                with respect to those users of the application who visit without transacting business (“Visitors”)
                                and Visitors who register to transact business on the application and make use of the various
services offered by Ventazer (collectively, “Services”) (“Authorized Customers”).</p>

                                <ul class="list-unstyled ui-steps">
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Personally Identifiable Information
            </h5>
                                            <p>
                                                Refers to any information that identifies or can be used to identify, contact, or locate the person
                                                to whom such information pertains, including, but not limited to, name, address, phone number,
                                                fax number, email address, financial profiles, social security number (if requested or used), and
                                                credit card information. Personally Identifiable Information does not include information that
                                                is collected anonymously (that is, without identification of the individual user) or demographic
                                                information not connected to an identified individual.
            </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                What Personally Identifiable Information is collected?
            </h5>
                                            <p>
                                                We may collect basic user profile information from all of our Visitors and Authorized
                                                Customers. We collect the following additional information from our Authorized Customers:
                                                any and all information available belonging to the Authorized Customer in order to transact
                                                business on our application.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                What Organizations are Collecting the Information?
            </h5>
                                            <p>
                                                In addition to our direct collection of information, our third-party service vendors (such as
                                                credit card companies, clearinghouses and banks) who may provide such services as credit,
                                                may collect this information from our Visitors and Authorized Customers. We do not control
                                                how these third parties use such information. Some of these third parties may be intermediaries
                                                that act solely as links in the purchase/distribution chain, and do not store, retain, or use the
                                                information given to them.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                How does the Application use Personally Identifiable Information?
            </h5>
                                            <p>We use Personally Identifiable Information to customize the Application, to make appropriate
                                            service offerings, and to fulfill buying and selling requests on the Application. We may email
                                            Visitors and Authorized Customers about research or purchase and selling opportunities on the
                                            Application or information related to the subject matter of the Application. We may also use
                                            Personally Identifiable Information to contact Visitors and Authorized Customers in response
to specific inquiries, or to provide requested information.</p>
                                            <p>Ventazer uses the information that it collects about you to improve your browsing experience
                                            by personalizing its Application to your interest, and also to help Ventazer select content and
                                            services that you may find interesting and useful. Information about the content you visit and
                                            services you use may be used alone or in conjunction with information collected from other
                                            users to help us tailor our application and its other products and services to better suit the needs
and interests of you and other users.</p>
                                            <p>Information that you provide may also be used as part of Ventazer's effort to keep you informed
                                            about events, and selected products and services that may be of interest to you. In some cases,
                                            Ventazer may contact you with information from selected third-parties. There may be times
                                            when your information may be shared with third-parties pursuant to a process that contemplates
such sharing.</p>
                                            <p>Ventazer reserves the right to disclose any information that it obtains through its application to
                                            appropriate governmental or regulatory authorities, if required by law or any governmental
agency with a lawful request for same.</p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                With Whom the Information May be Shared?
            </h5>
                                            <p>
                                                Personally Identifiable Information about Authorized Customers may be shared with other
                                                Authorized Customers who wish to evaluate potential transactions with other Authorized
                                                Customers. We may share aggregated information about our Visitors, including the
                                                demographics of our Visitors and Authorized Customers, with our affiliated agencies and third
                                                party vendors. We also offer the opportunity to “opt out” of receiving information or being
                                                contacted by us or by any agency acting on our behalf.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                How is Personally Identifiable Information Stored?
            </h5>
                                            <p>
                                                Personally Identifiable Information collected by Ventazer is securely stored and is not
                                                accessible by third-parties or employees of Ventazer except for use as indicated above.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                What Choices are Available to Visitors Regarding Collection, Use and Distribution of
                                                the Information?
            </h5>
                                            <p>
                                                Visitors and Authorized Customers may opt out of receiving unsolicited information from us
                                                and/or our vendors and affiliated agencies by responding to emails as instructed, or by
contacting us at <a href="mailto:developer@ventazer.com">developer@ventazer.com</a>.
            </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Are Cookies Used on the Application?
            </h5>
                                            <p>
                                                Cookies are used for a variety of reasons. We use Cookies to obtain information about the
                                                preferences of our Visitors/Authorized Customers and the services they select. We also use
                                                Cookies for security purposes to protect our Authorized Customers. For example, if an
                                                Authorized Customer is logged on and the application is unused for more than 10 minutes, we
                                                will automatically log the Authorized Customer off.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                How does Ventazer Use Login Information?
            </h5>
                                            <p>
                                                Ventazer uses login information, including, but not limited to, IP addresses, ISPs, and browser
                                                types, to analyze trends, administer the Application, track a user’s movement and use, and
                                                gather broad demographic information.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                What Partners or Service Providers have access to Personally Identifiable Information
                                                from Visitors and/or Authorized Customers on the Application?
            </h5>
                                            <p>
                                                Ventazer has or will enter into and will continue to enter into partnerships and affiliations with
                                                a number of vendors. Such vendors may have access to certain Personally Identifiable
                                                Information on a need to know basis for evaluating Authorized Customers for service
                                                eligibility. Our privacy policy does not cover their collection or use of this information.
            </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Disclosure of Personally Identifiable Information to Comply with Law.
            </h5>
                                            <p>
                                                We will disclose Personally Identifiable Information in order to comply with a court order or
                                                subpoena or a formal request from a law enforcement agency to release information. We will
                                                also disclose Personally Identifiable Information when reasonably necessary to protect the
                                                safety of our Visitors and Authorized Customers.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                How does the Application keep Personally Identifiable Information secure?
            </h5>
                                            <p>
                                                Our employees are familiar with our security policy and practices. The Personally Identifiable
                                                Information of our Visitors and Authorized Customers is only accessible to a limited number
                                                of qualified employees who are given a secured password in order to gain access to the
                                                information. We audit our security systems and processes on a regular basis. Sensitive
                                                information, such as credit card numbers are protected by encryption protocols, in place to
                                                protect information sent over the Internet by third-party vendors such as Stripe (see
                                                Disclaimers, Terms and Conditions and other Policies pertaining to Stripe. While we take
                                                commercially reasonable measures to maintain a secure application, electronic
                                                communications and databases are subject to errors, tampering and break-ins, and we cannot
                                                guarantee or warrant that such events will not take place and we will not be liable to Visitors
                                                or Authorized Customers for any such occurrences.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                How can Visitors correct any Inaccuracies in Personally Identifiable Information?
            </h5>
                                            <p>
                                                Visitors and Authorized Customers may contact us to update Personally Identifiable
Information about them or to correct any inaccuracies by emailing us at <a href="mailto:developer@ventazer.com">developer@ventazer.com</a>
                                            </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Can a Visitor delete or Deactivate Personally Identifiable Information Collected by the
                                                Application?
            </h5>
                                            <p>
                                                We provide Visitors and Authorized Customers with a mechanism to delete/deactivate
                                                Personally Identifiable Information from the Application’s database by contacting us via email.
                                                However, because of backups and records of deletions, it may be impossible to delete a
                                                Visitor’s entry without retaining some residual information. An individual who requests to have
                                                Personally Identifiable Information deactivated will have this information functionally deleted,
                                                and we will not sell, transfer, or use Personally Identifiable Information relating to that
                                                individual in any way moving forward.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                What Happens if the Privacy Policy Changes?
            </h5>
                                            <p>
                                                Our privacy policy may change from time to time. We will let our Visitors and Authorized
                                                Customers know about changes to our privacy policy by posting such changes on the
                                                Application. However, if we are changing our privacy policy in a manner that might cause
                                                disclosure of Personally Identifiable Information that a Visitor or Authorized Customer has
                                                previously requested not be disclosed, we will contact such Visitor or Authorized Customer to
                                                allow such Visitor or Authorized Customer to prevent such disclosure.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                The Use of Cookies
            </h5>
                                            <p>
                                                This application places cookies on your computer to better provide you with accurate and
                                                meaningful advertising. Placing cookies on our visitors' computers better helps us understand
                                                our potential client base alongside providing our users with optimal user experience based on
                                                their web browser of choice. Cookies also help us reach out to you; you may see our
                                                advertisements throughout your web session after you visit our application. Furthermore, the
                                                advertising you see on this application, displayed as clickable links and other media, is specific
                                                to you and your browsing history. By visiting this application, you understand and comply with
                                                our use of cookies. Ventzer.com does not give or sell your personal data to any unaffiliated
third-party providers.</p>
                                            <p>Ventazer application collects certain information about visitors, even when they are not logged
                                            in. Ventazer may record your IP address, the URLs of the websites and pages you visit (before,
                                            during and after your visit to Ventazer application), the times and dates of such visits,
                                            information about the computer hardware and software you use and other information that may
                                            be available. Also, Ventazer may place "cookies" on your computer to recognize you on return
                                            visits to enable Ventazer to enhance your browsing experience, as well as to collect additional
                                            information about you. You may adjust your browser settings or use other means to prevent
                                            cookies from being placed on your computer, but doing so may result in reduced functionality
and a less personalized browsing experience.</p>
                                            <p>Ventazer may ask you to provide certain additional information about yourself, including your
                                            name, contact information, the organization you come from and certain other information that
                                            Ventazer may use to identify you. If you choose not to provide such information, you may not
be permitted to access certain Application features, content or services.</p></div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Clear GIFs
            </h5>
                                            <p>At times, Ventazer works with third-party service partners that employ clear GIFS (which may
                                            also be known as pixel tags, retargeting pixels, single pixel GIFs, action tags or web beacons)
                                            which allow us to effectively measure the effectiveness of advertising. Clear, tiny GIFs are
                                            application components with a unique identifier. They function in a similar way to cookies,
                                            and are used to track the movement of online users. Clear GIFs are different from cookies as
                                            they are on the page, are invisible, and are much smaller. In fact, they are only about the size
                                            of the period at the end of this sentence. Clear GIFs are not tied to Personally Identifiable
Information and only track visitor traffic and behavior on Ventazer.</p>
                                            <p>Ventazer is committed to the protection of your privacy, and will treat all of the information
                                            you provide through Ventazer application, including your registration information, with the
                                            utmost respect. Ventazer works diligently to ensure that it has taken all appropriate
                                            administrative and technical measures to prevent the unauthorized or unlawful use of your
                                            personally identifiable information, and to prevent any accidental loss or destruction of, or
                                            damage to, such information. Ventazer will not sell or disclose personally identifiable
                                            information about you to unaffiliated third parties except in accordance with this "Privacy
Policy".</p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Links and Additional Services
            </h5>
                                            <p>
                                                The Ventazer application contains links to other applications and/or websites. Please note that
                                                when you click on one of these links, you are moving to another application/website. We
                                                encourage you to read the privacy statements of these linked applications/websites as their
                                                privacy policies may differ from ours. The links you see on this application are not under the
                                                control of Ventazer. Ventazer cannot and will not be held responsible for the content of any
                                                linked application/website, directly or indirectly. Please refer to their respective privacy
policies for more information.</p>
                                            <p>From time to time, Ventazer may offer additional services through Ventazer application. In
                                            some cases additional services may be subject to alternative terms of use (as identified by
                                            Ventazer), and your use of any such services will constitute your acceptance of such alternative
                                            terms of use. Unless otherwise stated, such alternative terms of use shall apply to supplement
                                            this Agreement and in the context of any conflicting terms, this Agreement shall govern with
                                            respect to the Ventazer application, content and services, excepting only those services which
                                            are expressly covered, and then only to the extent so expressly covered, by such alternative
terms.</p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Information Sent by Way of the Application is Not Secure
            </h5>
                                            <p>
                                                Any information sent to Ventazer by Internet e-mail or through the application may not be
                                                secure and is done so on a non-confidential basis. Any information you send Ventazer over the
                                                Internet, either through Ventazer's application or via email is done at your own risk.
            </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Unsubscribe
            </h5>
                                            <p>
                                                Users seeking to opt-out should reply to any marketing communication with the subject line
'unsubscribe'.</p>
                                            <p>VENTAZER copyright 2019</p>
                                        </div>
                                    </li>

                                </ul>

                                <h2 class="text-header">
                                    Ventazer Purchase Policy
                    </h2>
                                <p class="mb-0 text-content">
                                    Our goal is to make your purchasing experience easy, efficient and equitable, so we can get you on your way to live events as quickly as possible. The following is designed to ensure your satisfaction and understanding of the purchase process on the Ventazer application where this appears, including Applications and applications where we may facilitate ticket resale transactions (collectively, the "Application"). If you have any questions, please contact us. Your purchases on our Application is subject to these terms. Please also review our Terms of Use which govern your use of our Application.
                    </p>
                                <ul class="list-unstyled ui-steps">
                                    <li class="media">
                                        <div class="media-body">
                                            <h5>
                                                Currency
                                </h5>
                                            <p>
                                                All ticket prices for events that occur in the United States are stated in U.S. Dollars. All ticket prices for events that occur in other countries are stated in its local currency.
                                </p>
                                        </div>
                                    </li>
                                    <li class="media">
                                        <div class="media-body">
                                            <h5>
                                                Payment Methods
            </h5>
                                            <p>
                                                We accept several methods of payment to accommodate your needs. If the event for which you are buying tickets is located in the United States, Ventazer.com accepts American Express, Visa, MasterCard, Discover, Diner's Club, and Ventazer gift cards for qualifying events.
            </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">
                                        <div class="media-body">
                                            <h5>
                                                Who You Are Buying From
            </h5>
                                            <p>
                                                Ventazer acts as the agent to those who provide events, such as venues, teams, artist representatives and fan clubs, promoters and leagues ("Event Providers"). When you purchase a ticket for an event that is located in the United States, then Ventazer, Inc., will be handling the transaction and collecting payment for the Event Provider via Stripe.
                                                Tickets obtained from unauthorized sources may be lost, stolen or counterfeit, and if so, are void.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Pricing and Availability
            </h5>
                                            <p>
                                                We sell tickets on behalf of Event Providers, which means we do not set the ticket prices or determine seating locations. Tickets are generally sold through online. We do not control this inventory or its availability. Check out our FAQs section for more information on ticket availability.
            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Order Confirmation
           </h5>
                                            <p>
                                                If you do not receive a confirmation number (in the form of a confirmation page or email) after submitting payment information, or if you experience an error message or service interruption after submitting payment information, it is your responsibility to confirm via your Ventazer account or Ventazer customer service whether or not your order has been placed. Only you may be aware of any problems that may occur during the purchase process. We will not be responsible for losses (monetary or otherwise) if you assume that an order was not placed because you failed to receive confirmation.
           </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Number of Tickets or "Ticket Limits"
           </h5>
                                            <p>
                                                When purchasing tickets on our Application, you are limited to a specified number of tickets for each event (also known as a "ticket limit"). This ticket limit is posted during the purchase process and is verified with every transaction. This policy is in effect to discourage unfair ticket buying practices. We reserve the right to cancel any or all orders and tickets without notice to you if you exceed the posted limits. This includes orders associated with the same name, e-mail address, billing address, credit card number or other information.
           </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Opening Acts / Festival Acts
           </h5>
                                            <p>
                                                Opening acts or guests may sometimes tour with headlining performers. We are not always made aware of opening acts or the length of their performances. Opening acts, as well as festival performers, are subject to change or cancellation at any time without notice. No refund will be owed if an opening act or festival performer is changed or canceled.
           </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Canceled and Rescheduled Events
           </h5>
                                            <p>
                                                Occasionally, concerts are canceled or postponed. Should this occur, we will attempt to contact you to inform you of refund or exchange procedures for that concert. For exact instructions on any canceled or postponed concert, please check the concert information online or contact us via email. If an event is canceled, and you purchased your ticket through Ventazer.com, or a third-party, we will automatically issue you a refund to the credit card, debit card or gift card that you used to purchase that ticket. </p>
                                            <p>If the event was moved or rescheduled, the Event Provider may set refund limitations. Please contact us for more information or to request a refund if you cannot attend the rescheduled event. Please make sure to include/provide your account number so we can expedite your refund if applicable.
           </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Refunds and Exchanges
           </h5>
                                            <p>
                                                Ventazer adheres to Stripes Return and exchange Policies as set for herein: All payments for tickets are conducted through Stripe. Stripe supports the ability to refund charges made to your account, either in whole or in part. If the original charge underwent currency conversion, the refunded amount is converted back using the same process. There are no fees to refund a charge, but the fees from the original charge are not returned. </p>
                                            <p>Stripe submit refund requests to your customer’s bank or card issuer immediately. Your customer sees the refund as a credit approximately 5-10 business days later, depending upon the bank. Once issued, a refund cannot be canceled. Disputes and chargebacks aren’t possible on credit card charges that are fully refunded. </p>
                                            <p>Stripe will also send an email to your customer notifying them of the refund, if all of these conditions apply:
           </p>
                                            <ul>
                                                <li> The original charge was created on a Customer object in your Stripe account </li>
                                                <li>The Customer object has a stored email address </li>
                                                <li>You have <b>Email customers for refunds</b> enabled Some refunds—those issued shortly after the original charge—appear in the form of a reversal instead of a refund. In the case of a reversal, the original charge drops off the customer’s statement, and a separate credit is not issued. </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Issuing Refunds (Stripe)
           </h5>
                                            <p>
                                                <p>Refunds can be issued via the <b>API</b> or the <b>Dashboard</b> and are processed immediately. Once issued, a refund cannot be canceled. You can issue more than one refund against a charge, but you cannot refund a total greater than the original charge amount. </p>
                                                <p>Refunds can be sent back only to the original payment method used in a charge. It’s not possible to send a refund to a different destination (e.g., another card or bank account). </p>
                                                <p>Refunds to expired or canceled cards are handled by the customer’s card issuer and, in most cases, credited to the customer’s replacement card. If no replacement exists, the card issuer usually delivers </p>
                                                <p>the refund to the customer using an alternate method (e.g., check or bank account deposit). In rare cases, a refund back to a card may <b>fail</b>. </p>
                                                <p>Always attempt to refund card payments through Stripe and not using another method (e.g., cash or check). If a payment is found to be <b>fraudulent</b> and a <b>dispute</b> is received, you can lose both the disputed amount and the amount you refunded separately. </p>
                                                <p>For additional payment methods <b>(ACH, iDEAL, etc.)</b>, refund handling can vary from bank to bank. If a customer has closed their method of payment, the bank may return the refund to us—at which point it is marked as <b>failed</b>.</p>
                                            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Handling Failed Refunds
           </h5>
                                            <p>
                                                A refund can fail if the customer’s bank or card issuer has been unable to process it correctly (e.g., a closed bank account or a problem with the card). The bank returns the refunded amount to us and we add it back to your Stripe account balance. This process can take up to 30 days from the post date.
           </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Billing Information Verification
           </h5>
                                            <p>
                                                Orders are subject to credit card approval and processed only after a billing address, and other billing information, has been verified. Occasionally, we receive incorrect billing or credit card account information for a ticket order that can delay processing and delivery. In these cases, Ventazer customer service will attempt to contact you, using the information provided at the time of purchase. If we are unable to reach you after our initial attempt, we may cancel your order and may sell your tickets to another customer without further notice.
           </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Delivery Options
           </h5>
                                            <p>
                                                Different combinations of delivery methods may be offered, depending on factors that include the venue or country where the event is to be held, the country in which you live, how much time is left before the event starts, whether it is a holiday season and the nature of the demand for the event. Please carefully review the current list of delivery methods offered during the purchase process. For security purposes, we can only ship tickets to the billing address on file with the credit card company that is used for your purchase.
           <p>Generally, some of the following delivery methods may be offered: </p>
                                                <ul><li>Mobile - for events that occur in the United States or Canada if the venue has installed and enabled equipment necessary to support this technology.</li></ul>
                                            </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Pricing and Other Errors
           </h5>
                                            <p>
                                                If the amount you pay for a ticket is incorrect regardless of whether because of an error in a price posted on this Application or otherwise communicated to you, or you are able to order a ticket before its scheduled on-sale or presale date or you are able to order a ticket that was not supposed to have been released for sale, then we will have the right to cancel that ticket (or the order for that ticket) and refund to you the amount that you paid. This will apply regardless of whether because of human error or a transactional malfunction of this web Application or other Ventazer operated system.
           </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Multiple Browser Windows
           </h5>
                                            <p>
                                                When ordering tickets online with us, please ensure you are looking for tickets and placing an order using only one browser window. Looking up tickets using multiple browser windows could result in losing your tickets or timer expiration.
           </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Limitation of Liability
           </h5>
                                            <p>
                                                Balls, pucks, and other objects may fly into the spectator area during an event. Despite spectator shielding, injury can occur. Stay alert at all times before, during and after play or performance. If struck, immediately ask an usher for directions to a medical station. You voluntarily assume all risks and danger incidental to the event for which the ticket is issued, whether occurring before, during or after the event, and you waive any claims for personal injury or death against us, management, facilities, leagues, artists, other participants, and all of our respective parents, affiliated entities, agents, officers, directors, owners and employees on behalf of yourself and any accompanying minor. You bear all risks of inclement weather. Event date and time are subject to change. See the Limitation of Liability section in the <b>Terms of Use</b> for additional limits on our liability.
           </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                License; Ejection and Cancellation; No Redemption Value
           </h5>
                                            <p>
                                                You agree to comply with all of the Event Providers' applicable rules, policies, terms and conditions ("Event Provider Rules"). Event Providers reserve the right, without refund of any amount paid, to refuse admission to, or eject, any person whose conduct management deems disorderly, who uses vulgar or abusive language or who fails to comply with Event Provider Rules. Breach of terms or rules will terminate your license to attend the event without refund. A ticket is a revocable license and admission may be refused upon refunding the ticket's face amount. A ticket is not redeemable for cash.
           </p>
                                        </div>
                                    </li>
                                    <li class="media my-4">

                                        <div class="media-body">
                                            <h5>
                                                Recording, Transmission and Exhibition
           </h5>
                                            <p>
                                                You agree that the event for which you purchase tickets is a public or private event, that your appearance and actions inside and outside the venue where the event occurs are public or private in nature, and that you have no expectation of privacy (even at private events) with regard to your actions or conduct at the event. You grant permission to us and the Event Provider(s) to utilize your name, image, likeness, acts, poses, plays, appearance, movements, and statements in any live or recorded audio, video, or photographic display or other transmission, exhibition, publication or reproduction made of, or at, the event (regardless of whether before, during or after play or performance) for any purpose, in any manner, in any medium or context now known or hereafter developed, without further authorization from, or compensation to, you or anyone acting on your behalf.
           </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                You Are Subject to Search
           </h5>
                                            <p>
                                                You and your belongings may be searched on entry to the event. You consent to such searches and waive any related claims that may arise. If you elect not to consent to such searches, you may be denied entry to the event without refund or other compensation. Under certain facility rules, certain items may not be brought into the premises, including without limitation, alcohol, drugs, controlled substances, cameras, recording devices, laser pointers, strobe lights, irritants (e.g., artificial noisemakers), bundles and containers.
           </p>
                                        </div>
                                    </li>
                                    <li class="media">

                                        <div class="media-body">
                                            <h5>
                                                Unlawful Resale of Tickets; Promotions
           </h5>
                                            <p>
                                                Unlawful resale (or attempted resale), counterfeit or copy of tickets is grounds for seizure and cancellation without compensation. Certain maximum resale premiums and restrictions may apply in some states and we cannot be responsible for providing legal advice regarding resale or pricing of tickets. You are responsible for complying with all applicable ticket resale laws. In addition, we reserve the right to restrict or deny ticket purchasing privileges to anyone that we determine to be, or has been, in violation of our policies. Because we do not guarantee the authenticity of tickets purchased from any non-authorized third-party reseller (such as brokers or individuals), we
                                                recommend that you purchase tickets directly through us, authorized partners or from the venue box office to ensure ticket authenticity. Tickets may not be used for advertising, promotions, contests or sweepstakes, unless formal written authorization is given by us.
           </p>
                                        </div>
                                    </li>

                                </ul>
                            </div>

                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default PrivacyPolicy;