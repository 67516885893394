import React, { Component } from 'react';
import './index.css';

class BlockDownload extends Component {
    render() {
        return (
            <div className="section bg-gradient">
                <div className="container">
                    <div className="call-to-action">
                        <div className="box-icon">
                            <span className="ti-mobile gradient-fill ti-3x"></span>
                        </div>
                        <h2>Download Anywhere</h2>
                        <p className="tagline-pink">
                            Coming soon to all major mobile platforms.
                        </p>
                        <div className="my-4">
                            <a href="#" className="btn btn-light disabled">
                                <img alt="App Store" src="images/appleicon.png" />
                                App Store
                            </a>
                            <a href="#" className="btn btn-light disabled">
                                <img alt="Google Play" src="images/playicon.png" />
                                Google Play
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlockDownload;