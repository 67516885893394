import React from "react";

const DonationSuccess = () => {
  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12">
          <p>
            We will continue working on ventazer to improve the experience!
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center p-0">
          <img src="images/donation-success.png" alt="Congrats-donation"></img>
        </div>
      </div>
    </div>
  );
};

export { DonationSuccess };
