import React, { Component } from 'react';
import './index.css';

class Highlights extends Component {
    render() {
        return (
            <div className="section light-bg" id="features">
                <div className="container">
                    <div className="section-title">
                        <small>HIGHLIGHTS</small>
                        <h3>Features You Love</h3>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="card features">
                                <div className="card-body card-appariance d-flex align-items-center">
                                    <div className="media align-items-center col-12">
                                        <div className="col-3 p-0">
                                            <img src="images/smileface.svg" alt="Smileface icon" height="60px" width="60px"/>
                                        </div>
                                        {/* <span className="ti-face-smile gradient-fill ti-3x mr-3"></span> */}
                                        <div className="media-body col-9 text-center">
                                            <h4 className="card-title mb-0">Simple</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card features">
                                <div className="card-body card-appariance d-flex align-items-center">
                                    <div className="media align-items-center col-12">
                                        <div className="col-3 p-0">
                                            <img src="images/setting.svg" alt="Setting icon" height="60px" width="60px"/>
                                        </div>
                                        {/* <span className="ti-settings gradient-fill ti-3x mr-3"></span> */}
                                        <div className="media-body col-9 text-center">
                                            <h4 className="card-title mb-0">Customizable</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card features">
                                <div className="card-body card-appariance d-flex align-items-center">
                                    <div className="media align-items-center col-12">
                                    <div className="col-3 p-0">
                                        <img src="images/lock.svg" alt="Lock icon" height="60px" width="60px"/>
                                    </div>
                                        {/* <span className="ti-lock gradient-fill ti-3x mr-3"></span> */}
                                        <div className="media-body col-9 text-center">
                                            <h4 className="card-title mb-0">Secure</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Highlights;