const config = {
        apiKey: "AIzaSyB5unyCKGleQ1boX3KGezANvWVsmQnog3o",
        authDomain: "ventazer-25c75.firebaseapp.com",
        databaseURL: "https://ventazer-25c75.firebaseio.com",
        projectId: "ventazer-25c75",
        storageBucket: "ventazer-25c75.appspot.com",
        messagingSenderId: "280600508341",
        appId: "1:280600508341:web:fc454f1af8689baecd8bfc",
        measurementId: "G-M43PFH1Z6E"
    }

export default config;