import React, { Component } from "react";
import "../../index.scss";
import "../common/blank-nav.css";
import fb from "../../config/fb";
import { CheckoutForm } from "./presentation/card-minimal.js";
import { DonationSuccess } from "./presentation/donation-success.js";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class Donation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      amount: null,
      errors: {
        name: "",
        email: "",
        amount: "",
      },
      nameValid: false,
      emailValid: false,
      amountValid: false,
      amountBtn: {
        btnActive1: false,
        btnActive5: false,
        btnActive10: false,
        btnActive15: false,
        btnActive16: false,
      },
      currentStep: 0,
    };
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        this.state.nameValid = value.length > 2;
        errors.name =
          value.length < 2 && !this.state.nameValid
            ? "Name must be 1 characters long!"
            : "";
        break;
      case "email":
        this.state.emailValid = validEmailRegex.test(value);
        errors.email = this.state.emailValid ? "" : "Email is not valid!";
        break;
      case "amount":
        console.log("Entro acá", value);
        this.state.amountValid = value > 0;
        errors.amount =
          value.toString().length < 0 && !this.state.amountValid
            ? "Choose one amount"
            : "";
        break;
    }

    this.setState({ errors, [name]: value });
  };

  selectAmount = (event, value) => {
    event.preventDefault();
    let errors = this.state.errors;

    switch (value) {
      case 1:
        this.state.amountBtn.btnActive1 = true;
        this.state.amountBtn.btnActive5 = false;
        this.state.amountBtn.btnActive10 = false;
        this.state.amountBtn.btnActive15 = false;
        this.state.amountBtn.btnActive20 = false;
        break;
      case 5:
        this.state.amountBtn.btnActive1 = false;
        this.state.amountBtn.btnActive5 = true;
        this.state.amountBtn.btnActive10 = false;
        this.state.amountBtn.btnActive15 = false;
        this.state.amountBtn.btnActive20 = false;
        break;
      case 10:
        this.state.amountBtn.btnActive1 = false;
        this.state.amountBtn.btnActive5 = false;
        this.state.amountBtn.btnActive10 = true;
        this.state.amountBtn.btnActive15 = false;
        this.state.amountBtn.btnActive20 = false;
        break;
      case 15:
        this.state.amountBtn.btnActive1 = false;
        this.state.amountBtn.btnActive5 = false;
        this.state.amountBtn.btnActive10 = false;
        this.state.amountBtn.btnActive15 = true;
        this.state.amountBtn.btnActive20 = false;
        break;
      case 20:
        this.state.amountBtn.btnActive1 = false;
        this.state.amountBtn.btnActive5 = false;
        this.state.amountBtn.btnActive10 = false;
        this.state.amountBtn.btnActive15 = false;
        this.state.amountBtn.btnActive20 = true;
        break;
    }

    this.state.amountValid = value > 0;

    errors.amount =
      value.toString().length < 0 && !this.state.amountValid
        ? "Choose one amount"
        : "";

    this.setState({ errors, amount: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm(this.state.errors)) {
      this.setState({
        currentStep: 1,
      });
      console.info("Valid Form");
    } else {
      console.error("Invalid Form");
    }
  };

  buttonBack = (data) => {
    if (data) {
      this.setState({
        currentStep: 0,
      });
    }
  };

  handleSubmitSecondStep = async (data) => {
    if (data) {
      this.setState({
        currentStep: 2,
      });
    }
  };

  componentDidMount() {
    const analytics = fb.analytics();
    analytics.logEvent("Donation_webpage");
  }

  render() {
    const { errors, amountBtn, step } = this.state;

    const stepForm = [
      <div>
        <h3 className="mb-4"> Personal information </h3>
        <form method="POST" action="" onSubmit={this.handleSubmit} noValidate>
          <div className="form-group mb-4">
            <label htmlFor="name">Name </label>
            <input
              id="name"
              type="text"
              name="name"
              className="form-control"
              placeholder="What´s your name?"
              onChange={this.handleChange}
              noValidate
            />
            {errors.name.length > 0 && (
              <span className="error">{errors.name}</span>
            )}
          </div>
          <div className="form-group mb-4">
            <label htmlFor="email">Email address</label>
            <input
              id="email"
              type="email"
              name="email"
              className="form-control"
              placeholder="What´s your email"
              onChange={this.handleChange}
              noValidate
            />
            {errors.email.length > 0 && (
              <span className="error">{errors.email}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount (USD)</label>
            <div className="row">
              <div className="col-12">
                <div className="row m-0 d-flex justify-content-between">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-1">
                    <button
                      className={
                        amountBtn.btnActive1
                          ? "btn-amount"
                          : "btn-amount btn-noselect"
                      }
                      onClick={(e) => this.selectAmount(e, 1)}
                    >
                      1
                    </button>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-1">
                    <button
                      className={
                        amountBtn.btnActive5
                          ? "btn-amount"
                          : "btn-amount btn-noselect"
                      }
                      onClick={(e) => this.selectAmount(e, 5)}
                    >
                      5
                    </button>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-1">
                    <button
                      className={
                        amountBtn.btnActive10
                          ? "btn-amount"
                          : "btn-amount btn-noselect"
                      }
                      onClick={(e) => this.selectAmount(e, 10)}
                    >
                      10
                    </button>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-1">
                    <button
                      className={
                        amountBtn.btnActive15
                          ? "btn-amount"
                          : "btn-amount btn-noselect"
                      }
                      onClick={(e) => this.selectAmount(e, 15)}
                    >
                      15
                    </button>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 p-1">
                    <button
                      className={
                        amountBtn.btnActive20
                          ? "btn-amount"
                          : "btn-amount btn-noselect"
                      }
                      onClick={(e) => this.selectAmount(e, 20)}
                    >
                      20
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 p-0">
            <button
              type="submit"
              className="btn-donation col-12"
              disabled={
                !this.state.nameValid ||
                !this.state.emailValid ||
                !this.state.amountValid
              }
            >
              Next
            </button>
          </div>
        </form>
      </div>,
      <div className="form-group mb-4">
        <h3 className="mb-4"> Payment details </h3>
        <CheckoutForm
          onSubmit={this.handleSubmitSecondStep}
          onSubmitBack={this.buttonBack}
          getData={{
            amount: this.state.amount,
            email: this.state.email,
            name: this.state.name,
          }}
        />
      </div>,
      <div>
        <h3 className="mb-4"> Thank you {this.state.name} </h3>
        <DonationSuccess />
      </div>,
    ];

    return (
      <div className="header-blank h-100">
        <div className="section light-bg min-h p-xl-5 p-lg-5 p-md-5 p-sm-0">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex-column">
                <h2 className="title-donation">Donation</h2>
                <p className="text-justify paragraph-donation">
                COVID-19 has not only directly impacted the economy and our health, 
                but also the way we socialize. Human beings have a strong need to connect 
                and for many of us, this desire has reached its peak and we are eagerly 
                craving a return to our social lives.
                </p>
                <p className="text-justify paragraph-donation">
                We look forward to reuniting with our families, friends, and the people 
                around us once social distancing guidelines are relaxed. That is where 
                Ventazer comes in! Ventazer is a FEE-FREE platform that helps you to 
                connect with people in the most exciting way by participating in TazerGigs.
                </p>
                <p className="text-justify paragraph-donation">
                In order to maintain Ventazer as a fee-free event platform, we invite you to 
                support our cause with a donation of any size to help us with upkeep and early 
                growth costs.
                </p>

                <h3 className="title-donation">Disclaimer</h3>
                <p className="text-justify paragraph-donation">
                Donations are utilized for the continuous operation and development of the platform, 
                as well as exciting prizes for the Friend Referral Program (FRP).
                </p>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 pl-xl-5 pl-lg-5 pl-md-0 pl-sm-0">
                <div className="row">
                  <div className="col-12 custom-card p-xl-5 p-lg-5 p-md-5 p-sm-5">
                    {stepForm[this.state.currentStep]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Donation;
