import React, { Component } from 'react';
// import fire from './fb';
import fb from '../../config/fb'
import './index.css';

class Block1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdded: false,
            isGood: false,
            isValidEmail: true,
            email: ''    
        };
    }

    handleEmailChange = (event) => {
        this.setState({ 
            email: event.target.value,
            isAdded: false,
            isGood: false,
            isValidEmail: true
        });
      }

    addSignUp = (event) => {
        event.preventDefault(); // prevent form submit reloading the page
        var emailAddress = this.state.email;

        if (/.+@.+\.[A-Za-z]+$/.test(emailAddress)) {
            fb.auth().signInAnonymously();

            var signupsRef = fb.database().ref('signups');
            signupsRef.orderByChild('email').equalTo(emailAddress).once('value', snapshot => {
                console.log('Snapshot', snapshot.val());
                if (snapshot.val() === null) {
                    var data = {
                        email: emailAddress,
                        added: Date.now()
                    };
                    signupsRef.push(data); // Send the data to firebase
                    this.setState({ 
                        isAdded : false,
                        isGood: true,
                        isValidEmail: true,
                        email: ''
                    });
                } else {
                    this.setState({ 
                        isAdded : true,
                        isGood: false,
                        isValidEmail: true
                    });
                }
            });
        } else {
            this.setState({ isValidEmail: false });
        }
    }

    render() {
        let added;
        if (this.state.isAdded) {
            added = <div class="signup-invalid">Email is already registered.</div>;
        }

        let success;
        if (this.state.isGood) {
            success = <div class="signup-success">Thanks for signing up!</div>
        }

        let invalidEmail;
        if (!this.state.isValidEmail) {
            invalidEmail = <div class="signup-invalid">Please check your email address and try again.</div>;
        }

        return (
            <div className="section light-bg">
                <div className="container">
                    <div className="row" style={{zIndex:901, position: 'relative'}}>
                        <div className="col-lg-6 offset-lg-6">
                            <h2 className="text-header">Be One of the First</h2>
                            <p className="mb-4 text-content font-home">
                            Ventazer helps you meet new people in the most exciting way! 
                            It allows you to create or find events/parties and have the most exclusive people attend. 
                            Be one of the first to get access when we release our beta.
                            </p>
                            {/* <form onSubmit={this.addSignUp.bind(this)}>
                                <label className="sr-only" htmlFor="inlineFormInputGroupEmail">Email</label>
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">@</div>
                                    </div>
                                    <input type="email" className="form-control" id="inlineFormInputGroupEmail" placeholder="Enter email address" ref={ el => this.emailEl = el } value={this.state.email} onChange={this.handleEmailChange} />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="submit" id="button-addon">Get Early Access</button>
                                    </div>
                                </div>
                                {invalidEmail}
                                {success}
                                {added}
                            </form> */}
                        </div>
                    </div>
                    <div className="perspective-phone">
                        <img alt="perspective phone" className="img-fluid" src="images/iphonex_perspective.png" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Block1;