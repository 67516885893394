import React, { Component } from 'react';
import './index.css';

class Block2 extends Component {
    render() {
        return (
            <div className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src="images/dual_iphonex.png" alt="dual iphone" className="img-fluid" />
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div>
                                <h2 className="text-header">Buy &amp; Sell Tickets Without Fees</h2>
                                <p className="mb-4 text-content font-home">
                                At Ventazer you can discover exciting hidden events and parties in your local neighborhood or anywhere in the world.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block2;