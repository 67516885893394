import React from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { getSecret } from "./Donation.service.js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      border: "1px solid #ced4da",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = ({ onSubmit, onSubmitBack, getData }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setloading] = React.useState(false);
  const [error, setError] = React.useState(false);

  let validCard = true;

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setloading(true);
    setError(false);
    const clientSecret = await getSecret(getData);

    if (clientSecret) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmCardPayment(
        clientSecret.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: getData.name,
              email: getData.email
            },
            type: "card"
          },
        }
      );

      if (result.error) {
        console.log("[error]", result.error);
        setloading(false);
        setError(true);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          console.log("[paymentMethod]", result.paymentIntent.status);
          onSubmit(true);
          setloading(false);
        }
      }
    } else {
      setloading(false);
      setError(true);
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    onSubmitBack(true);
  };

  const handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row m-0">
        <CardElement
          className="payment-input col-12"
          options={CARD_ELEMENT_OPTIONS}
        />
      </div>
      <div className="row m-0">
        <div className="col-12 mt-2 p-0">
          {error ? (
            <p className="error-payment m-0">
              We could not made the Donation. You don't have enough funds or your card number is not valid.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row mt-2 ml-0 mr-0 d-flex justify-content-between">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 pr-1 pl-0 padding-custom pt-2">
          <button
            className="btn-donation-outline w-100"
            onClick={goBack.bind(this)}
          >
            Back
          </button>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 pr-0 pl-1 padding-custom pt-2">
          <button
            className="btn-donation w-100"
            type="submit"
            disabled={loading}
          >
            { loading && <img className="mr-2 spin-animation" src="images/loading.png"></img> }
            Donate <p style={{fontFamily: 'Roboto', display: "inline-block", margin:0}}>$</p> {getData.amount}
          </button>
        </div>
      </div>
    </form>
  );
};

export { CheckoutForm };
