import React, { Component } from "react";
import "./App.css";
import Navigation from "./components/Navigation";
import fb from "./config/fb";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import Header from './components/Header';
// import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
// import Home from './components/Home';
// import About from './components/About';
// import PrivacyPolicy from './components/common/PrivacyPolicy';
// import TermsConditions from './components/common/TermsAndConditions';
//import TermsConditions from './components/About';

const stripePromise = loadStripe("pk_live_yjza0s6uL5GunmhS1ojhFPU8");
class App extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false
    }
  }

  componentDidMount() {
    var analytics = fb.analytics();
    analytics.logEvent("home_web");
  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    // this.myRef.addEventListener("mouseout", this.handleNvEnter)

  }

  render() {
    return (
      <Elements stripe={stripePromise}>
        <div className="main">
          <Navigation />
        </div>
      </Elements>
    );
  }
}

export default App;
