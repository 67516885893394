import React, { Component, createRef } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap';
import './index.css';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import Home from '../../components/Home'
import PrivacyPolicy from '../../components/common/PrivacyPolicy';
import TermsConditions from '../../components/common/TermsAndConditions';
import Donation from '../common/Donation';

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isResponsive: false
        };
        // this.calcWidth();
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }


    render() {
        return (
            <Router basename="/">
                <div className="h-100 d-flex-column">
                    <nav className="container navbar space-nav navbar-expand-lg navbar-dark bg-light">
                        <NavbarBrand href="/" className="logo"><b>V</b>entazer</NavbarBrand>
                        {(window.innerWidth < 992) ? <ul className="navbar-nav navbar-right ">
                            <li><Link to={'/Donation'} className="nav-link nav-link-route">Contribute</Link></li>
                        </ul> : ''}
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                <ul className="navbar-nav navbar-right ml-auto">
                                    <li><Link to={'/'} className="nav-link nav-link-route">Home</Link></li>
                                    { (window.innerWidth > 992) ?
                                        <li><Link to={'/Donation'} className="nav-link nav-link-route">Contribute</Link></li>
                                        : ''
                                    }
                                    <NavLink href="/#features" className="nav-link nav-link-route">Features</NavLink>
                                    {/* <li><Link to={'/PrivacyPolicy'} className="nav-link nav-link-route">Privacy Policy</Link></li> */}
                                    {/* <li><Link to={'/TermsConditions'} className="nav-link nav-link-route">Terms and Conditions</Link></li> */}
                                </ul>
                            </Nav>
                        </Collapse>
                    </nav>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        {/* <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
                        <Route path='/TermsConditions' component={TermsConditions} /> */}
                        <Route path='/Donation' component={Donation} />
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default Navigation;