import React , { Component } from 'react';
import './index.css';

class SectionPurpose extends Component {
    render () {
        return (
            <div className="section custom-height d-flex align-items-center">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <h3 className="text-center">Your Event Platform, No Ticket Fees</h3>
                    </div>
                    <div className="row d-flex justify-content-center pr-5 pl-5">
                        <p className="text-center phrase">Support us on our mission to revolutionize the event & ticketing industry</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionPurpose;