import React, { Component, createRef } from 'react';
import Highlights from '../Highlights';
import Block1 from '../Block1';
import Block2 from '../Block2';
import Block3 from '../Block3';
import BlockDownload from '../BlockDownload';
import Header from '../Header';
import SectionPurpose from '../SectionPurpose';
import RankingSection from '../RankingSection';
// import Modal from 'react-modal';
import Modal from 'react-bootstrap/Modal'
import './index.css';
import { Button } from 'reactstrap';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import Donation from '../common/Donation'
// import InitFirebase from '../../config/fb'

var firebase;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Home extends Component {

    constructor() {
        super();
        // firebase = InitFirebase;
        // console.log('Se inicia la pagina web', firebase);
        this.state = {
            modalIsOpen: false
        }
        this.myref = createRef();
    }

    componentDidMount() {
        // firebase.logEvent('home_web');
        // this.myref.addEventListener("mouseleave", () => {
        //     this.openModal();
        // }, false);
    }


    componentWillUnmount() {
        // window.removeEventListener("mouseout", this.keepOnPage);
    }

    // openModal = () => {
    //     const storageShowModal = sessionStorage.getItem('modalShow');
    //     console.log(storageShowModal);
    //     if (storageShowModal === null) {
    //         this.setState({ modalIsOpen: true });
    //         sessionStorage.setItem('modalShow', true);
    //     }
    // }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    goDonate = () => {
        window.open("/Donation");
    }

    render() {
        return (
            <div ref={element => this.myref = element}>

                {/* <Modal show={this.state.modalIsOpen} onHide={this.closeModal}>
                    <Modal.Header>
                        <Modal.Title>Help grow Ventazer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>In order to maintain Ventazer as a fee-free event platform, we invite you to support our cause with a donation of any size to help us with upkeep and early growth costs. For every $1 donated, we will donate 30% of the proceeds to COVID-19 vaccine development programs and to help those in the event industry that have lost their jobs as a direct result of the pandemic.</Modal.Body>
                    <Modal.Footer>
                        <Route basename="/">
                            <Link to={'/Donation'} className="btn-goDonation">I want to help!</Link>
                        </Route>
                        <Button className="btn-close" onClick={this.closeModal}>
                            Think what we can do together and donate later
                        </Button>
                    </Modal.Footer>
                </Modal> */}

                <Header />
                <SectionPurpose />
                <Highlights />
                <RankingSection />
                <Block1 />
                <Block2 />
                <Block3 />
                <BlockDownload />
            </div>
        );
    }
}

export default Home;