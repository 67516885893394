const apiUrlLocal = "http://localhost:3000/api/Stripe";
const apiUrl = "https://ventazer.azurewebsites.net/api/Stripe";

export const getSecret = async (data) => {

  const paymentData = {
    Amount: data.amount,
    Currency: "usd",
    Email: data.email,
  };

  return fetch(apiUrl, {
  // return fetch(apiUrlLocal, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(paymentData),
  })
    .then((rest) => rest)
    .then((rest) => rest.json())
    .catch((err) => console.log(err));
};
