import React , { Component } from 'react';
import './index.css';

class RankingSection extends Component {
    render () {
        return (
            <div className="section d-flex align-items-center">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        {/* <div className="col-12 custom-flex justify-content-lg-between justify-content-md-between 
                        justify-content-sm-center justify-content-xs-center align-items-center"> */}
                            <div className="col-sm-12 col-md-6 col-lg-6 d-flex-column">
                                <h2 className="text-header">Earn Points</h2>
                                <p className="text-content font-home">Make it to the top of the leaderboard by referring your friends! 
                                    Create and attend events to earn additional points and become a Tazergod.</p>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <img className="img-fluid" src="images/dual_ranking.png" alt="Ranking"></img>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default RankingSection;