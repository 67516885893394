import React, { Component } from 'react';
import './index.css';

class Block3 extends Component {
    render() {
        return (
            <div className="section light-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 d-flex align-items-center">
                            <ul className="list-unstyled ui-steps">
                                <li className="media">
                                    <div className="circle-icon mr-4">1</div>
                                    <div className="media-body">
                                        <h5>Event Nexus</h5>
                                        <p>
                                            TazerGigs are hidden parties/events. 
                                            Users apply to attend, get screened, and are potentially accepted by the host. 
                                            If accepted, users will get tickets and the address for the event. 
                                            Create your first unforgettable experience NOW!!!
                                        </p>
                                    </div>
                                </li>
                                <li className="media my-4">
                                    <div className="circle-icon mr-4">2</div>
                                    <div className="media-body">
                                        <h5>Revolutionary</h5>
                                        <p>
                                            Users, event organizers, and artists can save up to 27% with our no ticket fees approach
                                        </p>
                                    </div>
                                </li>
                                <li className="media">
                                    <div className="circle-icon mr-4">3</div>
                                    <div className="media-body">
                                        <h5>Driven with Purpose</h5>
                                        <p>
                                            We are deeply focused on trying to find more ways for you (artist, attendee, and service provider) to save money, earn more, and save time. This
                                            is a core value of ours. We grow when you grow.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <img src="images/iphonex.png" alt="iphone" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Block3;